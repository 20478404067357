.footer {
    background-color: #000;
    height: 35px;
    width: 100%;
    padding: 10px;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: left;
}

.text-container {
    /*width: 100px;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: white-space 0.5s;
}

.text-container-par:hover .text-container{
    white-space: normal;
    overflow: auto;
    cursor: pointer;
}

.folder-container {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: #f5f5f5;
    margin-bottom: 1px;
    margin-top: 1px;
}

.close-button {
    float: right;
    top: 8px;
    right: 8px;
}
